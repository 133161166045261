<template>
  <div
    class="row hero-container-desktop"
    :style="{ backgroundImage: `url('${section?.blocks[0]?.image.preprocessedUrl}')` }">
    <div class="hero-window col-lg-6"/>
    <div class="hero-content col-lg-6 d-flex flex-column justify-content-center align-items-center">
      <div class="content-wrapper mx-lg-xxl px-lg-xxl">
        <h1 class="hero-header text-capitalize my-lg-lg me-sm mt-sm-xs">
          {{ section?.title }}
        </h1>
        <p class="subtitle my-0 my-lg-lg me-sm">
          {{ section?.blocks[0]?.text }}
        </p>
        <GetStartedButton
          v-if="isDesktop"
          location="Hero Image"
          class="w-50"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useGenericContentStore } from '@/store/modules/genericContent'
import GetStartedButton from '@/homepage/components/GetStartedButton.vue'
import { onMounted, computed } from 'vue'
import useScreenSize from '@shared/composables/screenSize.js'

const { isDesktop } = useScreenSize()

const store = useGenericContentStore()
const section = computed(() => store.sectionsByLocation.find(section => section.name === 'hero_image'))
onMounted(() => {
  store.fetchSections('homepage')
})
</script>

<style lang="scss" scoped>
.hero-container-desktop {
  background-size: cover;
  @include media-tablet-or-smaller {
    background-position: 20% top;
  }
}

.hero-window {
  aspect-ratio: 1 / 1;
}

.hero-content {
  background-color: rgba(255, 255, 255, 0.75);
  padding: 1rem;

  @include media-desktop {
    padding: 200px 120px;
  }

  @include media-tablet-or-smaller {
    align-items: center;
    text-align: center;
  }
}

.content-wrapper {
  word-wrap: break-word;
  white-space: normal;
}
</style>
