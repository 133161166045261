import { defineStore } from 'pinia'
import apiGenericContent from '@shared/api/genericContent'
import { ref } from 'vue'

export const useGenericContentStore = defineStore('generic-content-store', () => {
  const sectionsByLocation = ref([])
  const fetchSections = async (location) => {
    const response = await apiGenericContent.getGenericContentSections(location)
    sectionsByLocation.value = response.data
  }

  return {
    sectionsByLocation,
    fetchSections
  }
})
