<!-- TODO: ENG-3647 - Isolate shared code. -->
<template>
  <div v-if="submitted">
    <div class="popper">
      🎉
    </div>
    <div class="all-set">
      {{ successHeader }}
    </div>
    {{ successBody }}
  </div>
  <div
    v-else
    class="form">
    <div class="text-input">
      <input
        id="email-capture-input"
        v-model="email"
        placeholder="Email"
        @input="showError = false">
      <div v-if="showError">
        <label for="email-capture-input">
          Please enter a valid email address.
        </label>
      </div>
    </div>
    <TrackingButton
      :text="buttonText"
      app-entry-point="homepage"
      event-label="Email Capture"
      class="mt-lg"
      :fixed-width="isTabletOrSmaller"
      @click="onSubmit"/>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { postSubscribeToMarketing } from '@shared/api/marketing.js'
import useScreenSize from '@shared/composables/screenSize.js'
import TrackingButton from '@shared/components/ADORN/TrackingButton.vue'

const props = defineProps({
  buttonText: {
    type: String,
    required: true
  },
  source: {
    type: String,
    required: true
  },
  successHeader: {
    type: String,
    required: true
  },
  successBody: {
    type: String,
    required: true
  }
})
const { isTabletOrSmaller } = useScreenSize()

const email = ref('')
const submitted = ref(false)
const showError = ref(false)

const emit = defineEmits([
  'submit'
])

const isValidEmail = (email) => {
  const simpleEmailRegex = /^[^@\s]+@[^@\s.]+\.[^@.\s]+$/g
  return email.trim().match(simpleEmailRegex)
}

async function onSubmit () {
  if (isValidEmail(email.value)) {
    await postSubscribeToMarketing({ email: email.value, source: props.source })
    submitted.value = true
    if (window.analytics) {
      window.analytics.track('Subscribed to Marketing Emails', {
        source: props.source
      })
    }
    emit('submit')
  } else {
    showError.value = true
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  max-width: 600px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
}

.text-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  @include medium {
    width: 70%;
  }
}

input {
  border: 1px solid $ash;
  border-radius: 5px;
  padding: 12px;
  font-size: 16px;
  transition: outline 150ms ease;
  outline-color: $plum;
  width: 100%;
}

label {
  color: red;
}

.popper {
  font-size: 24px;
}

.all-set {
  margin-top: 8px;
  font-weight: 600;
}
</style>
