<!-- An AutoScroll Carousel with a configurable number of items per slide. -->
<template>
  <div class="carousel">
    <div class="row">
      <div class="col">
        <slot/>
      </div>
    </div>
    <div :class="{'overlay' : $slots.overlay}">
      <slot
        v-if="$slots.overlay"
        name="overlay"/>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.carousel {
  @include placeholder-loading-animation;
  position: relative;
  background-color: $light-ash;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: $spacing-lg;
  background-color: rgb($white, 0.75);
  color: $armor;
}
</style>
