import { computed, onMounted, onUnmounted, ref, toValue, watch } from 'vue'
import { useElementVisibility as vueUseElementVisibility } from '@vueuse/core'

const getEmptyRect = () => ({
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  scrollWidth: 0,
  scrollHeight: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
})
/**
 * @template T
 * @typedef {import('vue').MaybeRefOrGetter<T> | import('vue').ComputedRef<T>} ComposableInput
 */

/**
 * @typedef {ComposableInput<string|HTMLElement>} ElementInput
 */

/**
 * Normalizes the input to an HTMLElement.
 * @param {ElementInput} input
 * @returns {HTMLElement|null}
 */
export function toElement (input) {
  const value = toValue(input)
  return typeof value === 'string'
    ? document.querySelector(value)
    : value instanceof HTMLElement
      ? value
      : null
}

/**
 * Composable to check if an element is present in the DOM.
 * @param {ElementInput} input
 */
export function useElementPresence (input) {
  const isPresent = ref(false)

  const observer = new MutationObserver(() => {
    isPresent.value = toElement(input) !== null
  })

  onMounted(() => {
    observer.observe(document.body, { childList: true, subtree: true })
  })
  onUnmounted(() => {
    observer.disconnect()
  })

  return isPresent
}

/**
 * Composable to get the size and position of an element.
 * @param {ElementInput} input
 */
export function useElementPosition (input) {
  const contentRect = ref(getEmptyRect())

  function update (element = toElement(input)) {
    if (element) {
      const { x, y, width, height, top, right, bottom, left } = element.getBoundingClientRect()
      const { scrollWidth, scrollHeight } = element
      contentRect.value = { x, y, width, height, scrollWidth, scrollHeight, top, right, bottom, left }
    }
  }
  const resizeObserver = new ResizeObserver((entries) => {
    update(entries[entries.length - 1].target)
  })
  const mutationObserver = new MutationObserver(() => {
    update()
  })

  const isPresent = useElementPresence(input)
  watch(isPresent, (value) => {
    if (value) {
      const element = toElement(input)
      resizeObserver.observe(element)
      mutationObserver.observe(element, { attributes: true, childList: true, subtree: true })
    } else {
      resizeObserver.disconnect()
      mutationObserver.disconnect()
      contentRect.value = getEmptyRect()
    }
  })

  onMounted(update)

  return computed(() => ({
    isPresent: isPresent.value,
    ...contentRect.value
  }))
}
export { useElementPosition as useElementSize }

/**
 * Composable to get the scroll position and size of an element.
 * @param {ElementInput} input
 */
export function useElementScrollPosition (input) {
  const boundingClientRect = ref(getEmptyRect())

  const contentRect = useElementPosition(input)
  watch(contentRect, setBoundingClientRect)

  function setBoundingClientRect () {
    const element = toElement(input)
    if (element) {
      const { x, y, width, height, top, right, bottom, left } = element.getBoundingClientRect()
      const { scrollWidth, scrollHeight } = element
      boundingClientRect.value = { x, y, width, height, scrollWidth, scrollHeight, top, right, bottom, left }
    } else {
      boundingClientRect.value = getEmptyRect()
    }
  }

  let waiting = false
  function onScroll () {
    if (!waiting) {
      waiting = true
      requestAnimationFrame(() => {
        setBoundingClientRect()
        waiting = false
      })
    }
  }

  onMounted(() => {
    document.addEventListener('scroll', onScroll)
  })
  onUnmounted(() => {
    document.removeEventListener('scroll', onScroll)
  })

  return computed(() => ({
    isPresent: contentRect.value.isPresent,
    ...boundingClientRect.value
  }))
}

/**
 * Callback for when an element becomes visible.
 * @callback OnVisibleCallback
 * @param {boolean} visible
 * @returns {void}
 */

/**
 * Composable to track the visibility of an element, with the option to only trigger once
 * when the element becomes visible for the first time.
 * @param {import('@vueuse/core').MaybeComputedElementRef} element
 * @param {OnVisibleCallback?} callback
 * @param {{ once: boolean }} options
 */
export function useElementVisibility (element, callback = null, options = { once: false }) {
  const hasBeenVisible = ref(false)
  const visible = vueUseElementVisibility(element)
  if (typeof callback === 'function') {
    const unwatchVisible = watch(visible, (visible) => {
      const shouldCallback = !options.once || !hasBeenVisible.value
      hasBeenVisible.value = hasBeenVisible.value || visible
      if (options.once && visible) {
        unwatchVisible()
      }
      if (shouldCallback) {
        callback(visible)
      }
    }, { immediate: true })
  }
  return visible
}
