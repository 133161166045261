<template>
  <div class="ticker-loop-scroll">
    <div
      class="marquee"
      :style="{
        '--col-size': columnSize,
        '--duration': speed + 's'
      }">
      <div class="marquee__group">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="marquee__item">
          <slot :item="item"/>
        </div>
      </div>
      <div
        aria-hidden="true"
        class="marquee__group">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="marquee__item">
          <slot :item="item"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import useScreenSize from '@shared/composables/screenSize.js' // TODO: ENG-3647 - Isolate shared code.

const props = defineProps({
  items: {
    type: Array,
    required: true,
    default: () => []
  },
  speed: {
    type: Number,
    default: 150
  },
  colSizes: {
    type: Object,
    default: () => { return { mobile: 12, tablet: 6, desktop: 4 } }
  }
})

const { screenSizeValue } = useScreenSize()
const columnSize = screenSizeValue(props.colSizes)

</script>

<style lang="scss" scoped>

.marquee {
  display: flex;
  overflow: hidden;
  --scroll-end: calc(-100% - var(--bs-gutter-x));
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: $spacing-sm;
  min-width: 100%;
  animation: marquee-scroll var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

@keyframes marquee-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

.marquee .marquee__item {
  display: grid;
  place-items: center;
  width: calc(var(--col-size) / 12 * 100vw);
}
</style>
