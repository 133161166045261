<template>
  <div class="bg-origami row text-center py-xl">
    <h2>Named Best Professional Styling</h2>
    <img
      class="my-md"
      width="222"
      height="33"
      :src="getImageUrl('logos/cosmo.svg')">
    <p class="offset-2 col-8">
      All members have access to free personal styling with a human who cares. Your stylist will have the know-how and the tools to get your ideal styles in front of you.
    </p>
    <div>
      <TrackingButton
        class="mt-xl mx-auto d-block"
        :fixed-width="isTabletOrSmaller"
        app-entry-point="homepage"
        event-label="Personal Styling"
        text="Learn More"
        to="https://armoire.style/personal-styling/"/>
    </div>
  </div>
</template>
<script setup>
import { getImageUrl } from '@/homepage/utils/url.js'
import useScreenSize from '@shared/composables/screenSize.js'
import TrackingButton from '@shared/components/ADORN/TrackingButton.vue'

const { isTabletOrSmaller } = useScreenSize()
</script>
