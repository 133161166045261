<!--
Automatically scrolls through a single image at a time, with each image
supporting a title and link. Provides a detail slot for additional content.
-->
<template>
  <div class="sidekick-image-scroll row">
    <div
      ref="outerButtonContainer"
      class="position-relative col-lg-6">
      <AutoscrollCarousel
        ref="autoscrollCarousel"
        :items-per-slide="1"
        :items="items"
        :show-buttons="items.length > 1"
        :button-container="isMobile ? outerButtonContainer : innerButtonContainer"
        :indicator-container="indicatorContainer"
        :pause="pauseCarousel">
        <template #default="{ item }">
          <BaseLink :to="item.routeLocation">
            <div
              class="sidekick-item"
              :style="{ backgroundImage: `url('${item.imageUrl}')` }"/>
          </BaseLink>
        </template>
        <template #overlay="{ activeItems: [{ title }] }">
          <div class="overlay">
            <div>
              <TextLineLimit
                :allow-tooltip="true"
                :tag="isMobile ? 'h3' : 'h2'"
                class="title">
                {{ title }}
              </TextLineLimit>
              <div
                ref="innerButtonContainer"
                class="button-container"/>
            </div>
            <div
              ref="indicatorContainer"
              class="carousel-indicator"/>
          </div>
        </template>
      </AutoscrollCarousel>
    </div>
    <div
      ref="details"
      class="col-lg-6">
      <slot
        v-if="$slots.detail && activeItem"
        name="detail"
        :active-item="activeItem"/>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import AutoscrollCarousel from '@shared/components/ADORN/AutoscrollCarousel.vue'
import TextLineLimit from '@/components/global/sequin/TextLineLimit.vue'
import useScreenSize from '@shared/composables/screenSize.js'
import BaseLink from '@shared/components/BaseLink.vue'
import { useElementHover } from '@vueuse/core'

defineProps({
  items: {
    type: Array,
    required: true,
    validator: value => {
      return value.every(item => {
        return typeof item === 'object' &&
          typeof item.imageUrl === 'string' &&
          typeof item.title === 'string' &&
          ['object', 'string'].includes(typeof item.routeLocation)
      })
    }
  }
})

const outerButtonContainer = ref(null)
const innerButtonContainer = ref(null)
const autoscrollCarousel = ref(null)
const indicatorContainer = ref(null)
const details = ref(null)

const { isMobile } = useScreenSize()

const detailsHovered = useElementHover(details)
const pauseCarousel = computed(() => isMobile.value || detailsHovered.value)
const activeItem = computed(() => autoscrollCarousel.value?.activeItems[0])
defineExpose({
  activeItem
})
</script>

<style lang="scss" scoped>
.sidekick-image-scroll {
  .sidekick-item {
    aspect-ratio: 3 / 2;
    background-size: cover;
    background-position: center top;
    height: 100%;
  }

  .overlay {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-lg;

    & > :first-child {
      @include media-tablet-or-larger {
        display: flex;
        justify-content: space-between;
        column-gap: $spacing-xs;
      }
      @include media-mobile {
        .title {
          text-align: center;
        }
      }
    }
  }

  @include media-mobile {
    :deep(.scroll-button) {
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);

      &.scroll-button-left {
        left: $spacing-lg;
      }
      &.scroll-button-right {
        right: $spacing-lg;
      }
    }
  }

  @include media-tablet-or-larger {
    .button-container {
      display: flex;
      column-gap: $spacing-lg;
    }
  }
}
</style>
