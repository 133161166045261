import { onMounted, onUnmounted } from 'vue'
import { toElement } from '@/composables/element.js'

/**
 * Composable to respond to scroll wheel events
 * @param {ElementInput} input
 * @param {function(WheelEvent, HTMLElement)} callback
 */
export default function useScrollWheel (input, callback) {
  const onWheel = (event) => { callback(event, toElement(input)) }
  const options = { passive: false }
  onMounted(() => { toElement(input)?.addEventListener('wheel', onWheel, options) })
  onUnmounted(() => { toElement(input)?.removeEventListener('wheel', onWheel, options) })
}
