<template>
  <div class="row bg-origami">
    <div
      v-if="homepageSectionsLoaded && collections.length > 0"
      class="g-0">
      <h2 class="text-center mt-xl mb-md">
        Curated Collections
      </h2>
      <SidekickImageScroll
        ref="sidekick"
        :items="collections"
        class="g-0">
        <template #detail="{ activeItem }">
          <div class="detail d-flex flex-column justify-content-between">
            <TangentialScroll
              :full-bleed="true"
              :placeholder="!activeItem.styleColorsLoaded"
              class="g-0 mt-lg mt-lg-0 mt-xl-lg mt-xxl-xxl">
              <template v-if="activeItem.styleColorsLoaded">
                <FadeTransition
                  v-for="(styleColor, index) in activeItem.previewStyleColors"
                  :key="styleColor.id">
                  <StyleColorPreview
                    class="col-6 col-md-4"
                    :style-color="styleColor"
                    :style-color-set="activeStyleColorSet"
                    :style-color-source-index="index"
                    :eager-load="true"
                    :open-flyout="false"/>
                </FadeTransition>
              </template>
              <template v-else>
                <StyleColorPreviewPlaceholder
                  v-for="index in highlightCount"
                  :key="`placeholder-${index}`"
                  class="col-6 col-md-4"/>
              </template>
            </TangentialScroll>
            <TrackingButton
              :to="activeSectionHref"
              variant="secondary"
              event-label="View Collection"
              app-entry-point="homepage"
              text="View Collection"
              :fixed-width="isTabletOrSmaller"
              class="m-xl m-lg-lg"
              :class="{ 'align-self-center': isTabletOrSmaller }"/>
          </div>
        </template>
      </SidekickImageScroll>
    </div>
    <div
      v-else-if="!homepageSectionsLoaded"
      class="g-0">
      <TextPlaceholder
        variant="h2"
        size="large"
        class="mx-auto mt-xl mb-md"/>
      <SidekickImageScrollPlaceholder
        class="g-0">
        <template #detail>
          <TangentialScroll
            :full-bleed="true"
            :placeholder="true"
            class="g-0 mt-lg mt-lg-0 mt-xl-lg mt-xxl-xxl">
            <StyleColorPreviewPlaceholder
              v-for="index in highlightCount"
              :key="`placeholder-${index}`"
              class="col-6 col-md-4"/>
          </TangentialScroll>
        </template>
      </SidekickImageScrollPlaceholder>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useClosetStore } from '@shared/stores/closet.js'
import SidekickImageScroll from '@shared/components/ADORN/SidekickImageScroll.vue'
import SidekickImageScrollPlaceholder from '@shared/components/ADORN/SidekickImageScrollPlaceholder.vue'
import TextPlaceholder from '@/components/global/sequin/TextPlaceholder.vue'
import TangentialScroll from '@shared/components/ADORN/TangentialScroll.vue'
import StyleColorPreview from '@shared/components/ADORN/StyleColorPreview.vue'
import StyleColorPreviewPlaceholder from '@shared/components/ADORN/StyleColorPreviewPlaceholder.vue'
import TrackingButton from '@shared/components/ADORN/TrackingButton.vue'
import { useElementVisibility } from '@/composables/element.js'
import useScreenSize from '@shared/composables/screenSize.js'
import { getSectionHref } from '@shared/utils/route.js'
import FadeTransition from '@shared/components/ADORN/Transitions/FadeTransition.vue'

const closetStore = useClosetStore()
const { isTabletOrSmaller } = useScreenSize()
const { homepageSectionsLoaded, homepageSections, styleColorsMap } = storeToRefs(closetStore)

const highlightCount = 15
const collections = computed(() => homepageSections.value?.map(section => {
  const previewStyleColors = section.styleColors
    .map(styleColorId => styleColorsMap.value[styleColorId])
    .filter(styleColor => styleColor)
    .slice(0, highlightCount)
  return {
    id: section.id,
    title: section.name,
    imageUrl: section.bannerImageUrl,
    routeLocation: getSectionHref(section),
    previewStyleColors,
    styleColorsLoaded: previewStyleColors.length > 0
  }
}) ?? [])

const sidekick = ref(null)
const activeSectionId = computed(() => sidekick.value?.activeItem?.id)
const activeSection = computed(() => homepageSections.value.find(section => section.id === activeSectionId.value))
const activeSectionHref = computed(() => getSectionHref(activeSection.value))

const activeStyleColorSet = computed(() => ({
  ...activeSection.value
}))

useElementVisibility(sidekick, (visible) => {
  if (visible) {
    // Load style colors
    Promise.allSettled(homepageSections.value.map(
      ({ id, styleColors }) =>
        styleColors.length < highlightCount
          ? closetStore.loadStyleColors({ id, amount: highlightCount })
          : Promise.resolve()
    ))
  }
}, { once: true })
</script>

<style scoped lang="scss">
.detail {
  @include media-desktop {
    height: 100%;
  }
}

:deep(.style-color-preview-image) {
  border: 0.5px solid $ash;
}
</style>
