<template>
  <div class="sidekick-image-scroll row">
    <div class="position-relative col-lg-6">
      <AutoscrollCarouselPlaceholder class="carousel">
        <template #overlay>
          <div class="overlay">
            <div>
              <TextPlaceholder
                :variant="isMobile ? 'h3' : 'h2'"
                class="title"/>
            </div>
          </div>
        </template>
      </AutoscrollCarouselPlaceholder>
    </div>
    <div class="col-lg-6">
      <slot
        v-if="$slots.detail"
        name="detail"/>
    </div>
  </div>
</template>

<script setup>
import TextPlaceholder from '@/components/global/sequin/TextPlaceholder.vue'
import AutoscrollCarouselPlaceholder from '@shared/components/ADORN/AutoscrollCarouselPlaceholder.vue'
import useScreenSize from '@shared/composables/screenSize.js'

const { isMobile } = useScreenSize()
</script>

<style lang="scss" scoped>
.carousel {
  aspect-ratio: 3 / 2;
}

.overlay {
  display: flex;
  flex-direction: column;
  row-gap: $spacing-lg;

  .title {
    background-color: $light-ash;
  }

  & > :first-child {
    @include media-tablet-or-larger {
      display: flex;
      justify-content: space-between;
      column-gap: $spacing-xs;
    }
    @include media-mobile {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
