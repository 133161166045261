import { mergeRoutes, sharedRoutes } from '@shared/routes.js'
import { createRouter, createWebHistory } from 'vue-router'
import { addNoIndexTag, removeNoIndexTag } from '@/homepage/utils/noIndexTag.js'
import { useClosetStore } from '@shared/stores/closet'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment'
import { useExperimentsStore } from '@shared/stores/experiments'

import HomePageWrapper from '@/homepage/components/homepage/HomePageWrapper.vue'

import FaqPage from '@/homepage/components/faq/FaqPage.vue'
import HowItWorks from '@/homepage/components/howItWorks/HowItWorks.vue'
import ReferralHome from '@/homepage/components/referral/ReferralHome.vue'
import SustainabilityHome from '@/homepage/components/sustainability/SustainabilityHome.vue'

const routes = mergeRoutes(sharedRoutes, [
  {
    path: '/',
    component: HomePageWrapper,
    name: 'home'
  },
  {
    path: '/howitworks',
    component: HowItWorks,
    name: 'how-it-works'
  },
  {
    path: '/frequently-asked-questions',
    component: FaqPage,
    name: 'frequently-asked-questions'
  },
  {
    path: '/refer/:referralCode',
    component: ReferralHome,
    name: 'refer',
    meta: {
      hidePromoBanner: true,
      noindex: true
    }
  },
  {
    path: '/sustainability',
    component: SustainabilityHome,
    name: 'sustainability'
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404' // Shared route.
  }
])

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  }
})

router.beforeEach(async (to, _from) => {
  const hasQueryParams = route => !!Object.keys(route.query).length

  // Capture and store utm campaign attributes
  if (hasQueryParams(to)) {
    /* eslint-disable camelcase */
    const { utm_campaign, utm_source, utm_medium } = to.query
    if (utm_campaign) sessionStorage.setItem('utm_campaign', utm_campaign)
    if (utm_source) sessionStorage.setItem('utm_source', utm_source)
    if (utm_medium) sessionStorage.setItem('utm_medium', utm_medium)
    /* eslint-enable camelcase */
  }

  // Add the noindex meta tag to disable search indexing for routes that request it.
  // Once we've updated to more modern packages, switch to @unhead/vue library.
  const noindex = to.matched.slice().reverse().find(r => r.meta && r.meta.noindex)
  if (noindex) {
    addNoIndexTag()
  } else {
    removeNoIndexTag()
  }

  if (typeof window.analytics !== 'undefined') {
    window.analytics.page(to.fullPath)
  }

  await useExperimentsStore().untilExperimentsLoaded()
  if (useHomepageRedesignExperiment().showVariant.value) {
    const closetStore = useClosetStore()
    if (!closetStore.initialSectionsLoaded) {
      await closetStore.loadInitialData()
    }
  }
})

// Scroll to the element with the hash in the URL after the route has been rendered.
router.afterEach(async (to) => {
  // This won't work without a delay because the element hasn't been rendered yet.
  // Awaiting nextTick() is long enough for the element to be rendered, but scrolling
  // doesn't work for some reason.
  await new Promise(resolve => setTimeout(resolve, 0))
  const hash = to.hash
  if (hash) {
    const el = document.querySelector(hash)
    if (el) {
      el.scrollIntoView()
    }
  }
})

export default router
